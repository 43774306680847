import React from "react"
import { connect } from "react-redux"
import { Switch } from "react-router-dom"
import OpenRoute from "./open-route"
import ProtectedRoute from "./protected-route"
import routes from "./routes"
import { getListViewRoutes } from "./list-view-routes"
import { analyticsSelector, listViewSelector, userSelector } from "../selectors"
import { getAnalyticsRoutes } from "./analytics-routes"
import { getReportsRoutes } from "./reports-routes"
import { getBetaAnalyticsRoutes } from "./beta-analytics-routes"
import { logUserAmplitudeEvent, PAGE_VISITED } from "../common/amplitude-event-logging"
import { pingIntercom } from "../common/intercom-event-logging"
import { nestedFolderPathSelector } from "../selectors/list-view"
import { getFlagEnabled } from "../getFlagValue"

export const AppRoute = ({ authRequired = true, updateIsAuthorized, ...props }) => {
    const { isErrorPage, location, path, user } = props
    // skip logging page visits for anonymous user routes. They were causing us to go over our unique user limit.
    if (authRequired) {
        logUserAmplitudeEvent(PAGE_VISITED, { path: path, url: location?.pathname })
    }
    // we don't need to check if user exists here because we want this to happen with anonymous users too
    pingIntercom(user)
    if (authRequired) return <ProtectedRoute updateIsAuthorized={updateIsAuthorized} {...props} />
    if (updateIsAuthorized) updateIsAuthorized(!isErrorPage) // Set to true to render sidebar for OpenRoute
    return <OpenRoute {...props} />
}

const mapAppRouteStateToProps = state => {
    return {
        user: userSelector(state),
    }
}

export const MappedAppRoute = connect(mapAppRouteStateToProps)(AppRoute)

export class RouteList extends React.PureComponent {
    // get all list views and any possible nested list views based on nested list view paths
    // so we have all potential routes available for navigation
    listViewRoutes = this.props.listViews?.length
        ? getListViewRoutes(
              this.props.listViews,
              getFlagEnabled("WA-7882-nested-left-nav") ? this.props.nestedListViewPaths : []
          )
        : []
    analyticsRoutes =
        this.props.analyticsDashboards && this.props.analyticsDashboards.length
            ? [
                  ...getAnalyticsRoutes(this.props.analyticsDashboards),
                  ...getReportsRoutes(this.props.analyticsDashboards),
                  ...getBetaAnalyticsRoutes(this.props.analyticsDashboards),
              ]
            : []

    allRoutes = [...this.listViewRoutes, ...this.analyticsRoutes, ...routes]

    render() {
        return (
            <Switch>
                {this.allRoutes.map(route => {
                    const key = `${route.path}_${new Date().getTime()}`
                    return (
                        <MappedAppRoute
                            key={key}
                            updateMenuGroup={this.props.updateMenuGroup}
                            updateIsAuthorized={this.props.updateIsAuthorized}
                            {...route}
                        />
                    )
                })}
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        listViews: listViewSelector(state),
        analyticsDashboards: analyticsSelector(state),
        nestedListViewPaths: nestedFolderPathSelector(state),
    }
}

export const ConnectedRouteList = connect(mapStateToProps)(RouteList)
