import React from "react"
import { renderFieldTemplate } from "../../../new-custom-forms/templates/HandlebarsTemplate"

// Remove with WA-8562-fix-ff-template-col-export
const FFTemplateCellRenderer = (params: Record<string, any>) => (props: Record<string, any>): JSX.Element => {
    const { inputFields, template, field, resultType } = params
    if (!template) return <div>Missing Template</div>
    if (props.data?.[field]) return props.data?.[field]
    // the value can be a string or a json string of something like a list or object
    // try to parse it, and if not use it as the raw value
    const templateContext: Record<string, any> = {}
    inputFields?.forEach((fieldName: string) => {
        const contextName = fieldName.split("/").pop() ?? fieldName
        try {
            templateContext[contextName] = JSON.parse(props.data[fieldName])
        } catch (error) {
            templateContext[contextName] = props.data[fieldName]
        }
    })
    const formInfo = {
        template: template,
        context: templateContext,
    }
    const value = renderFieldTemplate(formInfo)
    const newRowData = { ...props.data, [field]: value }
    props?.node?.setData(newRowData)

    return (
        <div>{value ? value : resultType && ["number", "integer", "currency"].includes(resultType) ? 0 : ""}</div>
    )
}

export default FFTemplateCellRenderer
